import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./App.css";
import "./scss/style.scss";

import AuthService from "./services/AuthService";

import TheLayout from "./containers/TheLayout";
import Login from "./pages/Login";

class App extends Component {
  constructor(props) {
    super(props);
    this.auth = new AuthService();
    this.state = {
      auth: this.auth.isLoggedIn(),
    };

    this.onAuthChange = this.onAuthChange.bind(this);
    this.logout = this.logout.bind(this);
    this.getMe = this.getMe.bind(this);
  }
  componentDidMount() {
    document.title = "Calidad";
  }
  render() {
    return (
      <Router>
        {this.state.auth ? (
          <Switch>
            <Route
              path='/'
              name='Home'
              render={(props) => (
                <TheLayout {...props} logout={this.logout} getMe={this.getMe} />
              )}
            />
          </Switch>
        ) : (
          <Switch>
            <Route
              path='/Login'
              render={(routerProps) => (
                <Login
                  {...routerProps}
                  onAuthChange={this.onAuthChange}
                ></Login>
              )}
            />
            <Redirect from='*' to='/Login' />
          </Switch>
        )}
      </Router>
    );
  }
  onAuthChange() {
    this.setState({auth: this.auth.isLoggedIn()});
  }
  logout() {
    this.auth.logout();
    this.onAuthChange();
  }

  getMe() {
    return this.auth.getUser();
  }
}

export default App;
