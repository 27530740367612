import React from 'react'
import {CDropdown,CDropdownItem,CDropdownMenu,CDropdownToggle,CImg} from '@coreui/react'

import avatar from '../assets/7.jpg'

const TheHeaderDropdown =  ({logout,getMe}) => {
  const user = getMe();
  
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={avatar}
            className="c-avatar-img"
            alt={user.nombre}
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>{user.nombre}</strong>
        </CDropdownItem>
       
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
