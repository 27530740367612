import decode from 'jwt-decode';
import API from './Api';
export default class AuthService{
    //Incializar variables importantes
    constructor(domain){
        this.domain = domain || "http://localhost:3000/api"; //dominio del API server
        

        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

   
    login = async (valuesData) => {
      try {
        const response = await API.post('login',JSON.stringify(valuesData), {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            }
        });
        const {data} = response;
        this.setToken(JSON.stringify(data.token));

        // Agregar token al API de conexicon
        API.defaults.headers.common['Authorization'] = data.token;

        // Obtener datos del usuario
        const resDatosUsuario = await API.get('me')
        const {usuario: user,nombre,rol} = resDatosUsuario.data;
        
        
        localStorage.setItem('user',JSON.stringify(resDatosUsuario.data));
        localStorage.setItem('usuario',JSON.stringify(user));
        localStorage.setItem('nombre',JSON.stringify(nombre));
        localStorage.setItem('rol',JSON.stringify(rol));
      } catch (error) {
        throw error; 
          
      }
        
       
       


        
    }


    /**
     * Verificar si existe un token de usuario y sigue siendo valido
     */
    isLoggedIn(){
        
        return !!this.getToken();
    }

    setToken(token){
        
        localStorage.setItem('token_id',token);
    }

    getToken(){
        return localStorage.getItem('token_id');
    }

    logout(){
        
        localStorage.removeItem('token_id');
        
    }

    getUser(){
        if(localStorage.getItem('user') !== "undefined"){
            let objStorage = JSON.parse(localStorage.getItem('user'));
            return objStorage;
        }else{
            this.logout();
            localStorage.removeItem('user');
            localStorage.removeItem('token_id');
        }
        return null;
    }

    getUserAccess(){
        let user = this.getUser();
        if(user){
            return user.rol;
        }else{
            return false;
        }
    }

    getProfile(){
        return decode(this.getToken());
    }
}
