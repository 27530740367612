import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {
  CImg,
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import imageHeader from "../assets/textLogoSmall.png"; // relative path to image
// routes config
import routes from "../routes";

import {
  TheHeaderDropdown,
  /*TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks*/
} from "./index";

const TheHeader = ({logout, getMe}) => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({type: "set", sidebarShow: val});
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({type: "set", sidebarShow: val});
  };

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className='ml-md-3 d-lg-none'
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className='ml-3 d-md-down-none'
        onClick={toggleSidebar}
      />
      <CHeaderBrand className='mx-auto d-lg-none' to='/'>
        <CImg src={imageHeader} width={264} alt='Calidad' />
      </CHeaderBrand>

      <CHeaderNav className='d-md-down-none mr-auto'>
        <CHeaderNavItem className='px-3'>
          <CHeaderNavLink to='/rutas'>
            Rutas Críticas de evaluación
          </CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className='px-3'>
        <CIcon
          name='cil-account-logout'
          style={{cursor: "pointer"}}
          onClick={logout}
        />

        <TheHeaderDropdown logout={logout} getMe={getMe} />
      </CHeaderNav>

      <CSubheader className='px-3 justify-content-between'>
        <CBreadcrumbRouter
          className='border-0 c-subheader-nav m-0 px-0 px-md-3'
          routes={routes}
        />
        <div className='d-md-down-none mfe-2 c-subheader-nav'></div>
      </CSubheader>
    </CHeader>
  );
};

export default TheHeader;
