import React from 'react'
import {
  CAlert,
  
  CProgress,
  
} from '@coreui/react'

export default function AlertTime({errorText,valueShow}){
    const [visible, setVisible] = React.useState(valueShow)
    
    return(
        <CAlert
                color="warning"
                show={visible}
                closeButton
                onShowChange={setVisible}
              >
               {errorText}
                <CProgress
                  striped
                  color="warning"
                  value={Number(visible) * 20}
                  size="xs"
                  className="mb-3"
                />
              </CAlert>
    )
}