
export default [
    
   
  
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Calidad']
    },
    {
      _tag: "CSidebarNavItem",
      name: "Reporte de Evidencias (VAR)",
      to: "/evidencias",
      icon: "cil-image1",
    },
   
   
    
  
  ]
  
  