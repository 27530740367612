import React from "react";

//const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

//const Users = React.lazy(() => import('./views/users/Users'));
//const User = React.lazy(() => import('./views/users/User'));

/** Desde aqui yo las agrego */
// Menu de Calidad

const Usuarios = React.lazy(() => import("./pages/Usuarios/index.js"));
const AddUser = React.lazy(() => import("./pages/Usuarios/Add"));
const EditUser = React.lazy(() => import("./pages/Usuarios/Edit"));

// Menu de Catalogos
const Hitos = React.lazy(() => import("./pages/Hitos"));
const AddHitos = React.lazy(() => import("./pages/Hitos/Add"));
const EditHitos = React.lazy(() => import("./pages/Hitos/Edit"));

const Estaciones = React.lazy(() => import("./pages/Estaciones"));
const AddEstaciones = React.lazy(() => import("./pages/Estaciones/Add"));
const EditEstaciones = React.lazy(() => import("./pages/Estaciones/Edit"));

const RutasEvaluacion = React.lazy(() => import("./pages/RutasEvaluacion"));
const AddRutasEvaluacion = React.lazy(() =>
  import("./pages/RutasEvaluacion/Add")
);
const SelectRutasEvaluacion = React.lazy(() =>
  import("./pages/RutasEvaluacion/SelectRuta")
);
const AsignarRutasEvaluacion = React.lazy(() =>
  import("./pages/RutasEvaluacion/Asignar")
);
const VerRutasEvaluacion = React.lazy(() =>
  import("./pages/RutasEvaluacion/Ver")
);
const VerRutasPruebas = React.lazy(() =>
  import("./pages/RutasEvaluacion/Pruebas")
);
const VerRutasCrv = React.lazy(() => import("./pages/RutasEvaluacion/Crv"));
//    Sembrado
const Desarrollos = React.lazy(() =>
  import("./views/catalogos/sembrado/Desarrollos")
);
const Etapas = React.lazy(() => import("./views/catalogos/sembrado/Etapas"));
const Ubicaciones = React.lazy(() =>
  import("./views/catalogos/sembrado/Ubicaciones")
);
const NotFound = React.lazy(() => import("./views/pages/page404/Page404"));

// Evalacuones
const Evaluaciones = React.lazy(() => import("./pages/Evaluaciones"));
const UbicacionesEvaluadas = React.lazy(() =>
  import("./pages/Evaluaciones/Ubicaciones")
);
const EvaluacionesCreadas = React.lazy(() =>
  import("./pages/Evaluaciones/EvaluacionesCreadas")
);

// Imagenes y Bitacoras
const Evidencias = React.lazy(() => import("./pages/Evidencias"));
const ImagenesPreview = React.lazy(() => import("./views/ImagenPreviewView"));

// Urbanizacion
const MatrizUrbanizacion = React.lazy(() =>
  import("./pages/MatrizUrbanizacion")
);
const AddMatrizUrbanizacion = React.lazy(() =>
  import("./pages/MatrizUrbanizacion/Add")
);
const AsignarMatrizUrbanizacion = React.lazy(() =>
  import("./pages/MatrizUrbanizacion/Asignar")
);

const CadenamientoMatrizUrbanizacion = React.lazy(() =>
  import("./pages/MatrizUrbanizacion/Cadenamiento")
);

const RutaMatrizUrbanizacion = React.lazy(() =>
  import("./pages/MatrizUrbanizacion/Ruta")
);

const VerRutasCru = React.lazy(() => import("./pages/MatrizUrbanizacion/Cru"));

const EvaluacionesUrbanizacion = React.lazy(() =>
  import("./pages/EvaluacionesUrbanizacion")
);

const EvaluacionesCadenamientosUrbanizacion = React.lazy(() =>
  import("./pages/EvaluacionesUrbanizacion/Cadenamientos")
);
const EvaluacionesCreadasCadenamientosUrbanizacion = React.lazy(() =>
  import("./pages/EvaluacionesUrbanizacion/EvaluacionesCreadas")
);

const routes = [
  {path: "/", exact: true, name: "Home"},
  {path: "/dashboard", name: "Dashboard", component: RutasEvaluacion},
  {path: "/Notfound", exact: true, name: "NotFound", component: NotFound},
  //{ path: '/users', exact: true,  name: 'Users', component: Users },

  // Usuarios
  {
    path: "/usuarios",
    exact: true,
    name: "Usuarios",
    component: Usuarios,
  },
  {
    path: "/usuarios/add/:rolId",
    exact: true,
    name: "Agregar",
    component: AddUser,
  },
  {
    path: "/usuarios/edit/:userId",
    exact: true,
    name: "Editar",
    component: EditUser,
  },

  // Hitos
  {path: "/catalogos/hitos", name: "Hitos", component: Hitos, exact: true},
  {
    path: "/catalogos/hitos/add",
    name: "Agregar",
    component: AddHitos,
    exact: true,
  },
  {
    path: "/catalogos/hitos/edit/:hitoId",
    name: "Editar",
    component: EditHitos,
    exact: true,
  },

  // Estaciones
  {
    path: "/catalogos/estaciones",
    name: "Estaciones",
    component: Estaciones,
    exact: true,
  },
  {
    path: "/catalogos/estaciones/add",
    name: "Agregar",
    component: AddEstaciones,
    exact: true,
  },
  {
    path: "/catalogos/estaciones/edit/:estacionId",
    name: "Editar",
    component: EditEstaciones,
    exact: true,
  },

  // Rutas de evaluacion
  {
    path: "/rutas",
    name: "Rutas",
    component: RutasEvaluacion,
    exact: true,
  },
  {
    path: "/rutas/add",
    name: "Agregar Ruta de Evaluación",
    component: AddRutasEvaluacion,
    exact: true,
  },
  {
    path: "/rutas/add/:empresaId/:desarrolloId/:etapaCC",
    name: "Seleccionar Ruta",
    component: SelectRutasEvaluacion,
    exact: true,
  },

  {
    path: "/rutas/asignar/:rutaId",
    name: "Asignar Ruta",
    component: AsignarRutasEvaluacion,
    exact: true,
  },
  {
    path: "/rutas/ver/:rutaId",
    name: "Ver Ruta",
    component: VerRutasEvaluacion,
    exact: true,
  },
  {
    path: "/rutas/pruebas/:rutaId",
    name: "Pruebas Ruta",
    component: VerRutasPruebas,
    exact: true,
  },
  {
    path: "/rutas/crv/:rutaId",
    name: "Solicitud CRV Ruta",
    component: VerRutasCrv,
    exact: true,
  },
  {
    path: "/calidad/imagenes/:imagenId",
    name: "Imagenes",
    component: ImagenesPreview,
    exact: true,
  },

  // Aqui agregar mis rutas

  {
    path: "/catalogos/sembrado/desarrollos",
    exact: true,
    name: "Desarrollos",
    component: Desarrollos,
  },
  {
    path: "/catalogos/sembrado/desarrollos/etapas/:desarrolloId",
    exact: true,
    name: "Etapas",
    component: Etapas,
  },
  {
    path: "/catalogos/sembrado/desarrollos/etapas/ubicaciones/:etapaId",
    exact: true,
    name: "Ubicaciones",
    component: Ubicaciones,
  },
  {path: "/catalogos/sembrado/etapas", name: "Etapas", component: Etapas},

  // Evidencias
  {
    path: "/evidencias/:fechaInicio?/:fechaFinal?/:residente?/:desarrollo?/:etapa?/:manzana?/:lote?/:interior?",
    exact: true,
    name: "Evidencias",
    component: Evidencias,
  },

  // Evaluaciones
  {
    path: "/evaluaciones",
    exact: true,
    name: "Evaluaciones",
    component: Evaluaciones,
  },
  {
    path: "/evaluaciones/ubicaciones/:rutaId",
    name: "Ubicaciones Evaluadas",
    component: UbicacionesEvaluadas,
    exact: true,
  },
  {
    path: "/evaluaciones/ubicaciones/:rutaId/:ubicacionId",
    name: "Evaluaciones Creadas",
    component: EvaluacionesCreadas,
    exact: true,
  },

  // Urbanizacion
  {
    path: "/urbanizacion/matriz",
    name: "Matriz Ubranización",
    component: MatrizUrbanizacion,
    exact: true,
  },
  {
    path: "/urbanizacion/matriz/add",
    name: "Agregar Matriz Ubranización",
    component: AddMatrizUrbanizacion,
    exact: true,
  },

  {
    path: "/urbanizacion/matriz/asignar/:matrizUrbanizacionId",
    name: "Asignar Usuarios Matriz Ubranización",
    component: AsignarMatrizUrbanizacion,
    exact: true,
  },

  {
    path: "/urbanizacion/matriz/cadenamiento/:matrizUrbanizacionId",
    name: "Cadenamiento Matriz Ubranización",
    component: CadenamientoMatrizUrbanizacion,
    exact: true,
  },
  {
    path: "/urbanizacion/matriz/ruta/:matrizUrbanizacionId",
    name: "Ruta Matriz Ubranización",
    component: RutaMatrizUrbanizacion,
    exact: true,
  },
  {
    path: "/urbanizacion/matriz/cru/:cruId",
    name: "Solicitud CRU Ruta",
    component: VerRutasCru,
    exact: true,
  },

  // Evaluacion Urbanizacion

  {
    path: "/urbanizacion/evaluaciones",
    exact: true,
    name: "Evaluaciones Urbanizacion",
    component: EvaluacionesUrbanizacion,
  },
  {
    path: "/urbanizacion/evaluaciones",
    exact: true,
    name: "Evaluaciones Urbanizacion",
    component: EvaluacionesUrbanizacion,
  },
  {
    path: "/urbanizacion/evaluaciones/cadenamientos/:matrizUrbanizacionId",
    name: "Cadenamientos Evaluados",
    component: EvaluacionesCadenamientosUrbanizacion,
    exact: true,
  },
  {
    path: "/urbanizacion/evaluaciones/cadenamientos/:matrizUrbanizacionId/:cadenamientoId",
    name: "Evaluaciones Creadas",
    component: EvaluacionesCreadasCadenamientosUrbanizacion,
    exact: true,
  },
];

export default routes;
