export default [
  {
    _tag: "CSidebarNavTitle",
    _children: ["Residencial"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Rutas Críticas De Evaluación",
    to: "/rutas",
    icon: "cil-columns",
  },

  {
    _tag: "CSidebarNavItem",
    name: "Reporte de Evidencias (VAR)",
    to: "/evidencias",
    icon: "cil-image1",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Evaluaciones",
    to: "/evaluaciones",
    icon: "cil-check",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Urbanización"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Matrices de Ubranización",
    to: "/urbanizacion/matriz",
    icon: "cil-columns",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Evaluaciones",
    to: "/urbanizacion/evaluaciones",
    icon: "cil-columns",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Catalogos"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Usuarios",
    to: "/usuarios",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Catálogos",
    route: "/catalogos",
    icon: "cil-applications",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Hitos",
        to: "/catalogos/hitos",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Estaciones",
        to: "/catalogos/estaciones",
      },
    ],
  },
];
