import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
} from "@coreui/react";

import imageHeader from "../assets/textLogo.png";
// sidebar nav config
import navigation from "./_nav";
import navVisitantes from "./_navVisitantes";

const TheSidebar = ({getMe}) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);

  var nav;
  switch (getMe().rol.Id) {
    case 1:
    case 2:
      nav = navigation;
      break;

    default:
      nav = navVisitantes;
      break;
  }
  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({type: "set", sidebarShow: val})}
      style={{backgroundColor: "#333333"}}
    >
      <CSidebarBrand className='d-md-down-none' to='/'>
        <CImg
          className='c-sidebar-brand-full'
          src={imageHeader}
          width={128}
          alt='Calidad'
        />
        <CImg
          className='c-sidebar-brand-minimized'
          src={imageHeader}
          width={128}
          alt='Calidad'
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={nav}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>

      <CSidebarMinimizer className='c-d-md-down-none' />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
