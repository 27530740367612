import React, {Component} from "react";
import {
  CImg,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CRow,
} from "@coreui/react";
import {
  InputAdornment,
  TextField,
  Button,
  InputLabel,
  FilledInput,
  FormControl,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import {Person, Visibility, ExitToApp, VisibilityOff} from "@material-ui/icons";

import {Formik} from "formik";
import {withSnackbar} from "notistack";

import AuthService from "../services/AuthService";
import AlertTime from "../components/AlertTime";

import imageHeader from "../assets/LogoApp.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: 0,
      showError: false,
      errorText: "",
      showPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.authService = new AuthService();
    this.showPassword = false;
  }
  handleClickShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }
  handleSubmit(values, setSubmitting) {
    this.authService
      .login(values)
      .then((resp) => {
        this.props.onAuthChange();
        this.props.history.replace("/");

        setSubmitting(false);
      })
      .catch((err) => {
        var message = "Hubo un error al loguearse, intente mas tarde";

        if (err.response) {
          message = err.response.data.message;
        }
        if (err.message === "Network Error" || err.code === "ECONNABORTED") {
          message = "Error al conectarse al servidor, intente mas tarde";
        }
        this.props.enqueueSnackbar(message, {
          persist: false,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  }
  render() {
    return (
      <div className='c-app c-default-layout flex-row align-items-center'>
        <CContainer>
          <CRow className='justify-content-center'>
            <CCol md='8'>
              <CCardGroup>
                <CCard className='p-4 text-center'>
                  <CCardBody>
                    <CImg src={imageHeader} width={264} alt='App Calidad' />
                    <Formik
                      initialValues={{usuario: "", password: "", gethash: 1}}
                      validate={(values) => {
                        const errors = {};

                        if (!values.usuario) {
                          errors.usuario = "Requerido";
                        }
                        if (!values.password) {
                          errors.password = "Requerido";
                        }
                        return errors;
                      }}
                      onSubmit={(values, {setSubmitting}) => {
                        this.handleSubmit(values, setSubmitting);
                        // Mandar llamar al login
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          {this.state.showError && (
                            <AlertTime
                              errorText={this.state.errorText}
                              valueShow={5}
                            />
                          )}

                          <h1>Login</h1>
                          <p className='text-muted'>
                            Iniciar Sesion en su cuenta
                          </p>

                          <CInputGroup>
                            <TextField
                              name='usuario'
                              required
                              id='usuario'
                              label='Usuario'
                              fullWidth
                              variant='filled'
                              onChange={(event) =>
                                setFieldValue("usuario", event.target.value)
                              }
                              onBlur={handleBlur}
                              value={values.usuario}
                              helperText={
                                errors.usuario && touched.usuario ? (
                                  errors.usuario
                                ) : (
                                  <span>&nbsp;</span>
                                )
                              }
                              error={errors.usuario && touched.usuario}
                              onClick={handleBlur}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Person />
                                  </InputAdornment>
                                ),
                              }}
                              style={{marginBottom: 5, marginTop: 10}}
                            />
                          </CInputGroup>
                          <FormControl
                            variant='filled'
                            fullWidth
                            error={errors.password && touched.password}
                          >
                            <InputLabel htmlFor='filled-adornment-password'>
                              Password
                            </InputLabel>
                            <FilledInput
                              id='filled-adornment-password'
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              value={values.password}
                              onChange={handleChange("password")}
                              error={errors.password && touched.password}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                      this.handleClickShowPassword()
                                    }
                                    onMouseDown={() =>
                                      this.handleClickShowPassword()
                                    }
                                    edge='end'
                                  >
                                    {this.state.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText
                              id='my-helper-text'
                              error
                              style={{marginBottom: 10, marginTop: 10}}
                            >
                              {errors.password && touched.password ? (
                                errors.password
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </FormHelperText>
                          </FormControl>

                          <CRow>
                            <CCol>
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={handleSubmit}
                                fullWidth={true}
                                endIcon={<ExitToApp />}
                              >
                                INICIAR SESIÓN
                              </Button>
                            </CCol>
                          </CRow>
                        </form>
                      )}
                    </Formik>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}
export default withSnackbar(Login);
