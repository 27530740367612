import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store'
import { icons } from './assets/icons'
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';

React.icons = icons
const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
  <SnackbarProvider maxSnack={3} 
    
    ref={notistackRef}
    action={(key) => (
      <Button size="large" style={{margin: 0,padding:0,fontSize:10}} onClick={onClickDismiss(key)}>
          x
        </Button>
      
  )}
  >
    <Provider store={store}>
    <App/>
  </Provider>
  </SnackbarProvider>
  , 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
